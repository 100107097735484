// Define Javascript is active by changing the body class
document.body.classList.remove('no-js');
document.body.classList.add('js');

const header = document.getElementById('masthead');
const menuWrapper = document.querySelector('.menu-wrapper');
let lastScrollTop = window.scrollY || document.documentElement.scrollTop;
let scrollUp = false;

const isScrolled = () => {
  'use strict';

  const menuPosition = menuWrapper.offsetTop;
  const headerHeight = header.clientHeight;

  if (window.scrollY > menuPosition && isScrollUp()) {
    header.classList.add('scrolled');
    header.style.top = -menuPosition + 4 + 'px';
  } else {
    header.style.top = -headerHeight - 10 + 'px';
  }

  if (window.scrollY < headerHeight) {
    header.style.top = -headerHeight - 10 + 'px';
    header.classList.remove('scrolled');
  }
}

// Function to detect if user is scrolling down or up
const isScrollUp = () => {
  'use strict';

  // Return true immediately if "show on scroll up" is set to false
  if (stickyMenu.options.on_scroll === false) {
    return true;
  }

  let sensitivity = stickyMenu.options.scroll_sensitivity;

  let currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
  //console.log(scrollUp);
  console.log(stickyMenu.options);
  console.table(currentScrollPosition, lastScrollTop, sensitivity)

  if (currentScrollPosition < lastScrollTop - sensitivity) {
    lastScrollTop = currentScrollPosition;
    scrollUp = true;
    return scrollUp;
  } else if (currentScrollPosition > lastScrollTop) {
    scrollUp = false;
    lastScrollTop = currentScrollPosition;
    return scrollUp;
  }
  return scrollUp;

}




// Only run if sticky menu is set to on
if (stickyMenu.options.active) {
  window.addEventListener('scroll', isScrolled);
  window.addEventListener('resize', isScrolled);
}


/**
 * Custom menu toggle - non jQuery
 * TODO: Replace the menu completely, this is just a hack
 *
 */

const customMenuOpen = document.getElementById('custom-menu-toggle');
const customMenu = document.getElementById("shiftnav-main");
const customMenuClose = document.querySelector('#shiftnav-main .shiftnav-panel-close');

// Main menu open
customMenuOpen.addEventListener("click", () => {
  customMenu.classList.add("shiftnav-open-target");
  document.body.classList.add("shiftnav-open");
})

// Main menu close
customMenuClose.addEventListener("click", () => {
  customMenu.classList.remove("shiftnav-open-target");
  document.body.classList.remove("shiftnav-open");
})


const customSubMenuOpen = document.querySelectorAll('ul.shiftnav-menu > .shiftnav-sub-accordion span.shiftnav-submenu-activation-open');
const customSubMenuClose = document.querySelectorAll('ul.shiftnav-menu > .shiftnav-sub-accordion span.shiftnav-submenu-activation-close');
// Sub menu open
if (customSubMenuOpen.length > 0) {
  for (i = 0; i < customSubMenuOpen.length; i++) {
    customSubMenuOpen[i].style.width = '51px';
    customSubMenuOpen[i].style.height = '51px';
    customSubMenuOpen[i].addEventListener("click", (e) => {
      const parentLiElement = e.currentTarget.parentElement;
      const siblingSubMenu = parentLiElement.querySelector('.sub-menu');
      parentLiElement.classList.add('shiftnav-active');
      siblingSubMenu.classList.add("shiftnav-sub-active");
    })
  }
}
// Sub menu close
if (customSubMenuClose.length > 0) {
  for (i = 0; i < customSubMenuClose.length; i++) {
    // add inline style to customSubMenuClose
    customSubMenuClose[i].style.width = '51px';
    customSubMenuClose[i].style.height = '51px';
    customSubMenuClose[i].addEventListener("click", (e) => {
      const parentLiElement = e.currentTarget.parentElement;
      const siblingSubMenu = parentLiElement.querySelector('.sub-menu');
      parentLiElement.classList.remove('shiftnav-active');
      siblingSubMenu.classList.remove("shiftnav-sub-active");
    })
  }
}



// Sticky header with pageYOffset
// const stickyHeader = document.querySelector('.header-wrapper-sticky');
// window.onscroll = function (e) {
//   'use strict';
//   if (250 > window.pageYOffset && stickyHeader.classList.contains('scrolled')) {
//     stickyHeader.classList.remove('scrolled');
//   }
//   if (250 < window.pageYOffset && !stickyHeader.classList.contains('scrolled')) {
//     stickyHeader.classList.add('scrolled');
//   }
// };


// SlideOut for gjenvinningsstasjoner
// let stasjonContainer = document.getElementById('expanded-section-wrapper');
// let stasjonTrigger = document.getElementById('opening-hours__more');

// if (stasjonTrigger) {
//   stasjonTrigger.addEventListener('click', event => {
//     'use strict';
//     event.preventDefault();
//     if (!stasjonContainer.classList.contains('active')) {
//       stasjonContainer.classList.add('active');
//       stasjonContainer.style.height = 'auto';
//       let height = stasjonContainer.clientHeight + 'px';
//       stasjonContainer.style.height = '0px';
//       setTimeout(() => {
//         stasjonContainer.style.height = height;
//       }, 0);
//     } else {
//       stasjonContainer.style.height = '0px';
//       stasjonContainer.addEventListener('transitionend', () => {
//         stasjonContainer.classList.remove('active');
//       }, {
//         once: true
//       });
//     }
//   });
// }


// Slideout for tilbakemeldinger
let tilbakemeldingWrapper = document.getElementById('feedback-form-wrapper');
let tilbakemeldingContainer = document.getElementById('floating-feedback');
let tilbakemeldingTrigger = document.querySelector('.feedback-link-open');
//console.log(tilbakemeldingTrigger);
if (tilbakemeldingTrigger) {
  tilbakemeldingTrigger.addEventListener('click', event => {
    'use strict';
    event.preventDefault();
    if (!tilbakemeldingWrapper.classList.contains('active')) {
      tilbakemeldingContainer.classList.remove('hidden');
      tilbakemeldingWrapper.classList.add('active');
      tilbakemeldingWrapper.style.height = 'auto';
      tilbakemeldingTrigger.setAttribute('aria-expanded', true)
      let height = tilbakemeldingWrapper.clientHeight + 'px';
      tilbakemeldingWrapper.style.height = '0px';

      setTimeout(() => {
        tilbakemeldingWrapper.style.height = height;
      }, 0);
    } else {
      tilbakemeldingContainer.classList.add('hidden');
      tilbakemeldingWrapper.style.height = '0px';
      tilbakemeldingTrigger.setAttribute('aria-expanded', false)
      tilbakemeldingWrapper.addEventListener('transitionend', () => {
        tilbakemeldingWrapper.classList.remove('active');
      }, {
        once: true
      });
    }
  });
}

// Bildeslider på forsiden
document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.frontpage-splide')) {
    new Splide('.frontpage-splide', {
      type: 'loop',
      perPage: 1,
      speed: 1200,
      pauseOnHover: true,
      cover: true,
      focus: 'center',
      heightRatio: 0.64,
      gap: '2px'
    }).mount();
  }
});


// Searchbox - Avfall
const avfallInput = document.getElementById('keyword');
const resultsWindow = document.getElementById('datafetch');
if (avfallInput) {
  avfallInput.addEventListener('keyup', function (e) {
    if (this.value.length > 2) {
      resultsWindow.style.display = 'block';
    } else {
      resultsWindow.style.display = 'none';
    }
  })
}


function scrollToAnchor() {

  if (window.location.search) {
    const urlParams = new URLSearchParams(window.location.search);
    const anchor = urlParams.get('station');
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }
}

window.addEventListener('load', () => {
  scrollToAnchor();
});
